import React, { PureComponent } from 'react';
import dynamic from 'next/dynamic';

const HomePage = dynamic(() => import('src/components/HomePage/HomePage'), {
	ssr: false
});

export async function getServerSideProps(context) {
	const { req } = context;
	const host = req.headers.host;
	return {
		props: { host }, // will be passed to the page component as props
	};
}

class Index extends PureComponent {
	render = () => {
		const { host } = this.props;
		return (
			<div className={`fresh-theme`}>
				<HomePage host={host} />
			</div>
		);
	};
}

export default Index;
